import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <section
      data-theme="ice-blue"
      id="top"
      style={{ overflow: 'visible', height: '90vh' }}
    >
      <h1 className="flex flex--center" style={{ padding: '80px' }}>
        404: Not Found
      </h1>
    </section>
  </Layout>
);

export default NotFoundPage;
